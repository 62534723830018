import * as React from "react"

//styles
import "../styles/css/styles.css"


// components
import Header from "../components/Header"
import Footer from "../components/Footer"
import defaultsData from '../utils/formStructure';
import Form from "../components/shared/Form";
import Search from "../components/Search/index";

import Contact from "../components/Contact";

export default function Contato() {

  return(
    <div>
        <Header />
        <Search />
        <div className="main-form">
            <div className="container contato-title">
                <h1>Trabalhe Conosco</h1>
            </div>
            <section className="contato-custom">
                <Form type="Trabalhe Conosco" structure={defaultsData.TrabalheConosco} />
            </section>
        </div>
        <Footer />
    </div>
  )
}

export const Head = () => <title>Wega Motors</title>